.store_card .store_text h5 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #2c357d;
  padding-left: 12px;
}
.view-requestr {
  margin-top: 16px;
  background-color: #ffffff;
  padding: 20px 0;

  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}

.view-requestr .table {
  margin-bottom: 0;
}
.view-requestr tr {
  border-bottom: 1.19px solid #d5d9dd;
}

.view-requestr th,
.view-requestr td {
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  padding: 12px;
  border: none;
}

.view-requestr thead th {
  font-weight: 600;
}

.view-requestr .badge {
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 0;
  font-weight: 300;
}

.view-requestr .badge-approved {
  color: #34c75a;
  background: #d7f5e7;
  border-radius: 7.21px;
}

.view-requestr .badge-pending {
  background: #fff8e7;
  color: #d5b500;
  border-radius: 7.21px;
}

.view-requestr .badge-rejected {
  background: #ff323240;
  color: #ff3232;
  border-radius: 7.21px;
}

.view-requestb .btn-view {
  padding: 15px 25px;
  font-size: 14px;
  border: 0.65px solid #2c357d;
  color: #2c357d;
  width: 81px;
}
.btn-view:hover {
  color: #fff;
  background-color: #2c357d;
}
