@media (max-width: 1600px) {
  .top-bar .search-bar {
    width: 30%;
  }

  .administrator-dropdown {
    min-width: 100%;
  }

  .administrator-dropdown h3 {
    font-size: 26px;
    line-height: 35px;
  }

  .chat-card {
    padding: 25px;
  }
}

@media (max-width: 1400px) {
  .col-lg-9 {
    flex: 0 0 auto;
    width: 100%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 100%;
  }

  .col-lg-3.kids-card {
    flex: 0 0 auto;
    width: 25%;
  }

  .administrator-dropdown {
    min-height: auto;
    height: auto;
  }

  .top-bar .search-bar {
    width: 30%;
  }

  .top-bar .navbar-nav-right {
    width: 100%;
    justify-content: space-between;
  }

  .top-bar .search-bar {
    width: 30%;
  }

  .top-bar .navbar-nav li h5 {
    padding: 20px 30px;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    white-space: nowrap;
  }

  .top-bar .navbar-nav-right {
    justify-content: space-between;
    gap: 16px;
  }

  .h-100 {
    height: auto !important;
  }

  .tab-container.password-card {
    padding: 25px;
  }
}

@media (max-width: 1199px) {
  .layout-content-navbar .layout-page {
    padding-left: 0;
  }

  #layout-menu {
    width: 100%;
  }

  .layout-menu-toggle.navbar-nav {
    width: 56px;
    height: 56px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .layout-menu-toggle.navbar-nav .i {
    font-size: 30px;
  }

  .sales-dashboard h2 {
    font-size: 28px;
  }
}

@media (max-width: 991px) {
  .vector-fore {
    position: absolute;
    bottom: 0;
    display: none;
  }

  .profile-top {
    left: 17px;
    width: 96%;
  }

  .tab-container.password-card {
    margin-top: 50px;
  }

  .tab-buttons.d-grid {
    display: flex !important;
    justify-content: center !important;
  }
  .admin_text{
    display: none !important;
  }
}

@media (max-width: 1699px) {
  .card_wrap {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 1299px) {
  .card_wrap {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 1599px) {
  .cardmorning2 {
    padding: 14px 12px;
  }
}
/* @media (max-width: 991px) {
  .cardmorning2 {
    width: 48%;
  }
}
@media (max-width: 767px) {
  .cardmorning2 {
    width: 100%;
  }
} */
/* @media (max-width: 991px) {
  .cardmorningd {
    width: 100%;
  }
} */
/* @media (max-width: 1299px) {
  .cardmorning {
    width: 47%;
  }
  .cardmorning .vector-three {
    top: 100%;
    bottom: 0;
    display: none;
  }

  .cardmorning h2 {
    margin-bottom: 30px;
  }

  .totalnumber_card {
    box-shadow: unset;
  }

  .progress-contect {
    max-width: 100%;
    width: 100%;
  }

  .datatables-basic .odd td {
    white-space: nowrap;
  }
} */
@media (max-width: 999px) {
  .card_wrap {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 999px) {
  .cardmorning .vector-three {
    top: 100%;
    bottom: 0;
    display: none;
  }

  .cardmorning h2 {
    margin-bottom: 30px;
  }

  .totalnumber_card {
    box-shadow: unset;
  }

  .progress-contect {
    max-width: 100%;
    width: 100%;
  }

  .datatables-basic .odd td {
    white-space: nowrap;
  }
}
@media (max-width: 767px) {
  .cardmorning {
    width: 100%;
  }
}

@media (max-width: 1699px) {
  .totalnumber_card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* flex-direction: column; */
    gap: 14px;
  }

  .progress-contect {
    max-width: 100%;
    width: 100%;
  }

  .datatables-basic .odd td {
    white-space: nowrap;
  }
}
@media (max-width: 767px) {
  .cardmorning .vector-three {
    top: 100%;
    bottom: 0;
    display: none;
  }

  .cardmorning h2 {
    margin-bottom: 30px;
  }

  .totalnumber_card {
    box-shadow: unset;
  }

  .progress-contect {
    max-width: 100%;
    width: 100%;
  }

  .datatables-basic .odd td {
    white-space: nowrap;
  }
}

@media (max-width: 555px) {
  .cardmorning {
    padding: 25px;
  }

  .cardmorning h2 {
    font-size: 55px;
  }

  .totalnumber_card {
    box-shadow: unset;
  }

  .top-bar .navbar-nav li h5 {
    padding: 20px 10px;
  }

  html:not(.layout-footer-fixed) .content-wrapper {
    padding: 0px 15px;
  }

  .layout-page .navbar {
    margin: 0 15px;
    width: 100%;
  }

  .notifications {
    min-width: 390px;
    padding: 20px;
    border-radius: 18px;
  }

  .chat-card ul li small {
    line-height: 21px;
  }

  .notifications {
    padding: 20px;
  }

  /* .top-bar .profile-bar {
    width: 70%;
    position: absolute;
    top: 0;
    right: 27px;
  } */

  .top-bar .search-bar {
    width: 100%;
    margin-top: 30px;
  }

  .layout-page {
    margin-top: 20px;
  }

  .administrator-dropdown {
    padding: 25px;
  }

  .col-lg-3.kids-card {
    flex: 0 0 auto;
    width: 49%;
    margin-bottom: 20px;
  }

  .parents-modal .modal-content {
    padding: 30px;
  }

  .parents-modal {
    max-width: 100%;
    width: 100%;
    margin: auto;
  }

  .chat-card .card-header {
    margin-bottom: 35px;
    flex-wrap: wrap;
    gap: 20px;
  }

  .table-search-bar {
    width: 100%;
  }

  .kidsselect .form-select {
    width: 100%;
  }

  .notification-card {
    gap: 10px;
  }
}
.text_head h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #2c357d;
  padding-left: 10px;
}

@media (max-width: 480px) {
  /* .top-bar .profile-bar {
    width: 70%;
    position: absolute;
    top: 0;
    right: 13%;
    left: auto;
  } */

  .text_head h4 {
    font-size: 14px;
    line-height: 20px;
  }

  .top-bar .navbar-nav li h5 {
    padding: 20px 10px;
    font-size: 13px;
    display: none;
  }

  .sales-dashboard h2 {
    font-size: 25px;
    line-height: 30px;
  }

  .profile-img {
    min-height: 250px;
  }

  .profile-top {
    left: 17px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .chat-card .tab-button {
    padding: 14px 20px;
  }

  .notification-card {
    gap: 10px;
    flex-wrap: wrap;
  }

  .notification-card h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 34px;
    color: #000000;
    margin-bottom: 0;
  }

  .notification h3 {
    margin-bottom: 10px;
  }

  .chat-card .tab-button {
    gap: 10px;
  }
}

@media (max-width: 420px) {
  .notifications {
    min-width: 100%;
  }

  .notifications .dropdown-header {
    flex-wrap: wrap;
  }

  .dropdown-header h3 {
    font-size: 25px;
    line-height: 20px;
  }

  .dropdown-header h6 {
    font-size: 15px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    color: #000000;
    margin: 0;
    display: none;
  }

  .notifications {
    min-width: 300px;
  }

  .chat-card ul li small {
    font-size: 13px;
    line-height: 18px;
  }

  .chat-card .tab-button {
    padding: 13px 20px;
  }

  .tab-container.password-card {
    padding: 15px;
  }
}

@media (max-width: 360px) {
  .cardmorning h2 {
    font-size: 40px;
    margin-bottom: 0;
  }

  .totalnumber_card h4 {
    font-size: 16px;
  }

  .top-bar .navbar-nav li h5 {
    display: none;
  }

  /* .top-bar .profile-bar {
    width: 70%;
    position: absolute;
    top: 0;
    right: -13%;
    left: auto;
  } */

  .administrator-dropdown h2 {
    font-size: 50px;
    line-height: 60px;
  }

  .parents-modal .btn-primary {
    font-size: 15px;
  }

  .chat-card .tab-button {
    padding: 13px 20px;
    font-size: 17px;
  }
}

@media (max-width: 1077px) {
  .card.chat-card {
    width: fit-content;
  }
}

@media (max-width: 685px) {
  .navbar-nav.search-bar {
    display: none;
  }

  .top-bar .navbar-nav li {
    margin-right: 10px !important;
    width: 100%;
  }
}
.admin_text p {
  color: #252c58;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.5px;
  text-align: left;
  margin-bottom: 0px;
}
