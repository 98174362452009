.leave_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
@media (max-width: 1399px) {
  .leave_wrap {
    flex-wrap: wrap;
    gap: 17px;
  }
}
@media (max-width: 1199px) {
  .leave_wrap {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.right_req {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.right_req h4 {
  font-size: 16px;
  font-weight: 500;
  height: 23px;
  text-align: left;
  color: #878a99;
}

.min_flex_req h1 {
  color: #444050;
  font-size: 30px;
  font-weight: 600;
  height: "23px";
  text-align: left;
}

.logo_texts {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-bottom: 15px;
}
.des_texts h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;

  color: #000000;
}
.para_lorems {
  padding-top: 10px;
}
.para_loremss {
  padding-top: 20px;
}
.head_views {
  /* padding-bottom: 10px;*/
  border-top: 1px solid #2c357d;
}
.para_loremss .head_views .application_pins {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 2px;
}
.para_loremss .head_views .application_pins h6 {
  padding-top: 10px;
  color: #2c357d;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.head_viewss .application_pins {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 2px;
}
.head_viewss .application_pins h6 {
  padding-top: 10px;
  color: #2c357d;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.para_loremss .lorem_texts {
  border-bottom: 2px solid #d5d9dd;
}

.para_loremss .lorem_texts p {
  color: #2c357d;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
.lorem_textss {
  overflow-y: scroll;
  height: 125px;
  z-index: 10px;
}
.lorem_textss {
  border-bottom: 1px solid #d5d9dd;
}

.lorem_textss p {
  color: #2c357d;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  padding-bottom: 28px;
}
.add_textarea .heading_add {
  padding-top: 24px;
  padding-bottom: 12px;
}
.add_textarea {
  /* border-bottom: 0.6px solid #2c357d; */
  padding-bottom: 18px;
  /* height: 72px; */
}
.add_textarea textarea {
  width: 100%;
  border-radius: 10px;
  padding: 10px 10px;
  border: 0.6px solid #2c357d;
}

.add_textarea .heading_add h3 {
  color: #2c357d;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.skill_btns .skill_texts h4 {
  color: #2c357d;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.ocup_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.ocup_btns {
  padding: 10px 10px;
}
.ocup_btns span {
  color: #2c357d;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
}
.submit_buttons {
  /* padding: 10px 10px; */
}
.status-selector {
  color: #2c357d;
  padding: 8px 3px;
  border: none;
  outline: none;
  /* border-radius: 0px; */
  border-bottom: 1px solid #2c357d;
  width: 12%;
}
.statusdrop {
  border-bottom: 1px solid #d5d9dd;
  padding-bottom: 15px;
}
.statusdrop h6 {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
  padding-top: 10px;
}

.statusdrop.status-selector {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.submit_buttons button {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  border: none;
  border-radius: 6px;
  background-color: #2c357d;
  padding: 14px 55px;
  outline: none;
}
.cardmornings {
  box-shadow: 0px 1px 4px 0px #2c357d33;
  padding: 20px 28px;
  position: relative;
  border-radius: 10px;
  width: 100%;
  flex-wrap: nowrap;
}
@media (max-width: 1699px) {
  .cardmornings {
    box-shadow: 0px 1px 4px 0px #2c357d33;
    padding: 20px 23px;
    position: relative;
    border-radius: 10px;
    width: 100%;
  }
}

@media (max-width: 1399px) {
  .cardmornings {
    width: 49%;
  }
}
@media (max-width: 1199px) {
  .cardmornings {
    width: 100%;
  }
}

/* Apply flex-wrap: wrap when screen width is 1299px or less */

.right_reqs h4 {
  color: #878a99;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding-bottom: 10px;
}
.right_reqs .min_flex_reqs h1 {
  color: #2c357d;
  font-size: 14px;
  font-weight: 400;
  line-height: 2px;
  text-align: left;
  padding-bottom: 10px;
}
.btn_accept {
  padding-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d5d9dd;
}

.accept_1 button {
  padding: 12px 62px;
  background: #2c357d;
  border: 1px solid #2c357d;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}
.accept_1 :hover {
  border: 1px solid #2c357d;
  color: #2c357d;
  background: #ffff;
}

.reject_1 button {
  padding: 12px 62px;
  border: 1px solid #2c357d;
  border-radius: 6px;
  color: #2c357d;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  background-color: transparent;
}
.reject_1 :hover {
  background: #2c357d;
  color: #fff;
}

/* table css */

/* Styles scoped to .view-request */
.view-request {
  /* margin-top: 30px; */
  background-color: #ffffff;
  padding: 20px 0;
  /* border-top: 3.58px solid #d5d9dd; */
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}

.view-request .table {
  margin-bottom: 0;
}
.view-request tr {
  border-bottom: 1.19px solid #d5d9dd;
}

.view-request th,
.view-request td {
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  padding: 12px;
  border: none;
}

.view-request thead th {
  font-weight: 600;
}

.view-request .badge {
  padding: 8px 12px;
  font-size: 14px;

  font-weight: 300;
}

.view-request .badge-solved {
  color: #34c75a;
  background: #d7f5e7;
}

.view-request .badge-pending {
  background: #f2994a40;
  color: #ffb62e;
}

.view-request .badge-In-Progress {
  background: #bce0ff;
  color: #2c357d;
  /* padding: 8px 12px;
  font-size: 14px;
  font-weight: 300; */
}

.view-requestb .btn-view {
  padding: 15px 25px;
  font-size: 14px;
  border: 0.65px solid #2c357d;
  color: #2c357d;
  width: 81px;
}
.btn-view:hover {
  color: #fff;

  background-color: #2c357d;
}
