.checkboxWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  padding-left: 10px;

}

.checkboxLabel {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #2c357d80;
}

.checkboxInput {
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  border: 2px solid #2c357d;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}

/* Apply the styles when the checkbox is checked */
.checkboxInput:checked {
  background-color: #2c357d;
  border-color: #2c357d;
}

.checkboxInput:checked::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid #fff; /* White checkmark */
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); /* Create the checkmark */
}

/* Apply the focus box shadow */
.checkboxInput:focus {
  box-shadow: 0 0 5px #f3f3f3;
}
