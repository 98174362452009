.wrapper_login {
  background: radial-gradient(75.1% 75.1% at 50% 50%, #ffffff 0%, #2c357d 100%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 60px;
  .wrap_login {
    img {
      padding-top: 10px;
    }
  }
  .login_form {
    // padding: 25px 30px;
    width: 25%;
    h1 {
      color: #2c357d;
      font-size: 30px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: -0.01em;
      text-align: center;
    }
    .wrap_input {
      margin-top: 20px;
      position: relative;
      // padding-bottom: 6px;
      input {
        border: 1px solid #2c357d;
        background-color: transparent;
        padding: 9px 40px;
        width: 100%;
        border-radius: 4px;
      }
      ::placeholder {
        color: #2c357d;

        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        text-align: left;
      }
      .focus_input100 {
        left: 16px;
        position: absolute;
        top: 9px;
      }

      .input_feedback {
        color: red;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
      }
      .focus_eye {
        position: absolute;
        right: 12px;
        top: 8px;
      }

      p {
        color: #2c357d;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
      }
    }
    .contact100_form_checkbox {
      display: flex;
      align-items: center;
      padding: 12px;
      /* justify-content: flex-start; */
      /* gap: 22px; */
      padding-left: 22px;
      .flexCheckChecked {
        width: 10px;
      }
      .form-check-input {
        appearance: none; // Remove default styling
        width: 20px; // Adjust width
        height: 20px; // Adjust height
        border: 2px solid #2c357d; // Set border color
        border-radius: 4px; // Optional: Rounded corners
        background: radial-gradient(
          75.1% 75.1% at 50% 50%,
          #ffffff 0%,
          #2c357d 100%
        );
        cursor: pointer;
      }

      label {
        color: #2c357d;
        font-size: 14px;
        font-weight: 300;
        // margin-left: 10px;
        padding-left: 15px;
      }
    }
    .btn_dark {
      width: 100%;
      background-color: #2c357d;
      padding: 12px 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      color: #fff;
      border-radius: 4px;
      border: none;
    }

    // .for_get {
    h4 {
      color: #2c357d;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.5px;
      text-align: right;
      // }
    }

    @media (max-width:991px) {
      width: 90%;
    }
  }
}

.focus_eye2 {
  position: absolute;
  right: 10px;
  top: 10px;
}
