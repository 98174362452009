.overview {
}
.card_flex {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}
.flex_img_text {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  gap: 15px;
  width: 33%;
}

@media (max-width: 991px) {
  .card_flex {
    flex-direction: column;
  }
  .flex_img_text {
    width: 100%;
  }
  .detail_wrap {
    width: 100% !important;
    border-bottom: 1px dashed #2c357d;
    padding: 5px;
  }
  .pad_wrap {
    padding: 5px 0px !important;
  }
  .pad_wrap p:nth-child(1) {
   width: 35% !important;
  }
  .pad_wrap p:nth-child(2) {
   width: 65% !important;
  }
  .border_dash_right {
    display: none !important;
  }

}

.top_bottomEmployees {
  border-bottom: 1px solid #2c357d;
  padding-bottom: 10px;
}
.top_bottomEmployees h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #2c357d;
}
.text_name h6 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #000000;
}
.text_name p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;

  color: #7b808d;
}
.border_dash_right {
  border-right: dashed 1px #7b808d;
  padding: 50px;
  display: inline-block;
}
.border_dash_right {
  border-right: dashed 1px #7b808d;
  padding: 50px;
  display: inline-block;
}

@media (max-width: 1499px) {
  .border_dash_right {
    border-right: dashed 1px #7b808d;
    padding: 20px;
    display: inline-block;
  }
}
@media (max-width: 1299px) {
  .border_dash_right {
    border-right: dashed 1px #7b808d;
    padding: 10px;
    display: inline-block;
  }
}
.detail_wrap {
  padding: 10px 15px;
  width: 33%;
}
.pad_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;
  padding: 10px 10px;
}
.pad_wrap p {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  width: 50%;
}
