.file-upload-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #2c357d;
  overflow: hidden;
}

.file-icon {
  padding: 10px;
  background-color: white;
}

.file-name {
  flex-grow: 1;
  padding: 0px 10px;
  font-size: 16px;
  color: #2c357d;
  font-weight: 600;
  max-width: 100%;
}
@media (max-width: 1699px) {
  .file-name {
    flex-grow: 1;
    font-size: 10px;
    color: #2c357d;
    font-weight: 600;
    max-width: 100%;
  }
}

.btn-upload {
  background-color: #2c357d;
  color: white;
  padding: 11px 20px;
  border: none;
  cursor: pointer;

  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: center;
}
.input-logo-label {
  color: #2c357d;
  font-weight: 400;
}
