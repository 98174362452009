.card_body {
  text-align: center;
  overflow-y: scroll;
}

.card_body .table_inner tbody tr td {
  text-align: center;
}

.card_body .table_inner tbody tr .comntFeedback {
  text-align: left;
}

.card_body .table_inner tbody tr .comntFeedback .comntFeedbackspan {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
  display: inline-block;
  vertical-align: middle;
}

.card_body .table_inner ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.card_body .table_inner ul li:active {
  color: #000;
}
.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

td button {
  border: 0.65px solid #2c357d !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 8px;
  text-align: left;
  padding: 12px 20px;
  border-radius: 6.49px;
  border: 0.65px;
  color: #2c357d;
  background-color: transparent;
}
td button:hover {
  font-size: 14px;
  font-weight: 400;
  line-height: 8px;
  text-align: left;
  padding: 12px 20px;
  border-radius: 6.49px;
  border: 0.65px;
  color: #fff;
  background-color: #2c357d;
}
table td, .table th{
  vertical-align: middle;
}
