.logo_text {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  padding-bottom: 12px;
}
.des_text h5 {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  text-align: left;
  color: #2c357d;
}
.para_lorem .head_view h6 {
  color: #2c357d;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.head_view h6 {
  padding-bottom: 10px;
}
.lorem_text p {
  color: #2c357d;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
.skill_btn .skill_text h4 {
  color: #2c357d;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.skill_btns .skill_texts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px;
}

.skill_btns .skill_texts h4 {
  color: #2c357d;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.ocup_btn {
  display: flex;
  justify-content: flex-start;
  align-items: left;
  gap: 10px;
}
.ocup_btn {
  padding: 10px 0px;
}
.ocup_btn span {
  color: #2c357d;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  padding: 10px 10px;
  border-radius: 3px;
  background-color: #2c357d40;
}
.canditate_selector {
  padding-bottom: 30px;
  width: 25%;
}
.canditate_selector label {
  font-weight: 500;
}
.submit_button button {
  padding: 10px 10px;
  background-color: #2c357d;
  width: 12%;
  color: #fff;
  border: none;
}
.submit_button button {
  border-radius: 6px;

  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
/* job */
.view-requestb {
  margin-top: 15px;
  background-color: #ffffff;
  padding: 20px 0;
  /* border-top: 3.58px solid #d5d9dd; */
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}

.view-requestb .table {
  margin-bottom: 0;
}
.view-requestb tr {
  border-bottom: 1.19px solid #d5d9dd;
}

.view-requestb th,
.view-requestb td {
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  padding: 12px;
  border: none;
}

.view-requestb thead th {
  font-weight: 600;
}

.view-requestb .badge {
  padding: 8px 12px;
  font-size: 14px;

  font-weight: 300;
}

.view-requestb .badge-open {
  color: #34c75a;
  background: #d7f5e7;
}

.view-requestb .badge-pending {
  background: #f2994a40;
  color: #ffb62e;
}

.view-requestb .badge-closed {
  background: #ff323240;
  color: #ff3232;
}
.view-requestb .badge-Filled {
  background: #a2eddb40;
  color: #24e502;
}

.view-requestb .btn-view {
  padding: 15px 25px;
  font-size: 14px;
  border: 0.65px solid #2c357d;
  color: #2c357d;
  width: 81px;
}
.btn-view:hover {
  color: #fff;
  background-color: #2c357d;
}

@media (max-width:991px) {
  .canditate_selector{
    width: 100% !important;
  }
  .submit_button button{
    width: 100% !important;
  }
}