.notification-container {
  position: relative; /* Make it the reference point for the modal */
}

.notification-icon {
  cursor: pointer;
  font-size: 24px;
  position: relative;
}

.notification-overlay {
  background: transparent !important; /* Remove the default modal overlay */
}

.notification_confirm_popup {
  width: 30%;
  height: 400px;
  position: absolute !important; /* Position relative to the notification icon */
  top: 40px; /* Adjust based on icon's height */
  right: 0; /* Align it to the right of the icon */
  box-shadow: 0px 8px 8px 0px #10182826;
  border-radius: 12px;
  padding: 16px;
  background: white;
  z-index: 1000;
}

@media (max-width:991px) {
  .notification_confirm_popup{
    width: 90%;
  }
}

.top_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.top_flex img {
  cursor: pointer;
}
.top_flex h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #101828;
}

.massage_box {
  display: flex;
  gap: 25px;
  padding-top: 15px;
  background-color: #2c357d1a;
}
.massage_box .massage_image {
  padding-left: 10px;
}

.border_top {
  border-bottom: 0.5px solid #86888a80;
}

.border_horizontal {
  border-left: 3px solid #959abe;
  padding-left: 7px;
  /* height: 34px; */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 5px;
}

.massage_text h2 {
  font-size: 20px;
  font-weight: 600;
  /* line-height: 40px; */
  text-align: left;
  color: #000000;
}
.massage_text p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #313335;
}
.time_data {
  font-size: 12px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;

  color: #475569;
}
