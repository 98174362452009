.text_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.company_name h1 {
  color: #2c357d;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}
.btn_add button {
  padding: 10px 20px;
  background-color: #2c357d;
  border-radius: 6px;
  color: #ffff;
  border: none;
}
.container {
  margin-top: 20px;
  background-color: #ffffff;
  /* padding: 20px; */
  border-radius: 8px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}
.table-responsive table {
  border-left: none;
  border-right: none;
}
.table {
  border-left: none;
  border-right: none;
}

.comp-table .table th,
.comp-table .table td {
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  padding: 12px;
}

.comp-table .table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #252c58;
  /* border-left: none;
  border-right: none; */
}

.comp-table .table td {
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #2c357d;
}

.comp-table thead th {
  /* background-color: #f1f1f1; */
  font-weight: 600;
  vertical-align: middle;
}

.comp-table .table td .company-logo,
.comp-table .table td .signature {
  height: auto;
  max-width: 100px;
  object-fit: contain;
}

.comp-table .btn-add {
  float: right;
}

.comp-table .btn-save {
  width: 70px;
  font-size: 14px;
  padding: 5px 10px;
}

.comp-table .table-responsive {
  overflow-x: auto;
}
/* button {
  background-color: #2c357d;
  color: white;
  padding: 9px 18px;
  border-radius: 6px;
} */
/* Remove border around the file input */
input[type="file"] {
  border: none;
  outline: none;
  background: none;
  padding: 0;
}

/* Optional: Remove the background for file input */
input[type="file"]::-webkit-file-upload-button {
  background: transparent;
  border: none;
  border-right: 1px solid #2c357d2e;
  color: inherit;
  font-size: inherit;
}

/* Optionally, remove the default button style */
input[type="file"]::-ms-browse {
  background: transparent;
  border: none;

  border-right: 1px solid #2c357d2e;
}
.company .badge-active {
  background: #a2eddb40; /* Light green background */
  color: #24e502; /* Green text */
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
}

.company .badge-inactive {
  background: #ff323240; /* Light red background */
  color: #ff3232; /* Red text */
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
}
