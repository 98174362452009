.form {
  width: 100%;
  padding-top: 7px;
}
.form_control {
  display: block;
  /* padding: 0.375rem 0.75rem; */
  font-size: 1rem;
  line-height: 1.5;
  color: #2c357d;
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #2c357d26;
  border-radius: 0;
  /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  padding-right: 1px;
}
.inputLabel {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  padding: 0 10px;
}

.form_control input[type="date"],
.form_control input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  padding: 10px;
  font-size: 16px;
  color: #333;
}
/* 
.form_control input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  margin: 0;
} */

/* Hide number input arrows for Firefox */
/* .form_control input[type="number"] {
  -moz-appearance: textfield;
} */

.form_control::before {
  /* content: url('path/to/your/custom-calendar-icon.svg');  */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #ff6600; /* Change icon color here */
}
