.pagination_number {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  padding-top: 12px;
}

.pagination_number .page-item {
  margin: 0 5px;
  border: none;
  outline: none;
}

.pagination_number .page-item.active .page-link {
  background-color: #2c357d;
  border-color: #2c357d;
  color: white;
  border: none;
  outline: none;
}

.pagination_number .page-item .page-link {
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
  color: #2c357d;
  /* border: 1px solid #ddd; */
  border-radius: 3px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
}

.pagination_number .page-item .page-link:hover {
  background-color: #f1f1f1;
  color: #2c357d;
}
.pagination_number p {
  color: #252c58bd;
  font-size: 17.92px;
  font-weight: 400;
  line-height: 26.88px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
