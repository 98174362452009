.top_bottomEmployee {
  border-bottom: 1px solid #2c357d;
  padding-bottom: 10px;
}
.top_bottomEmployee h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #2c357d;
}
.row_col {
  width: 100%;
}
.row_col2 {
  width: 49%;
}
.custom-width-50 {
  width: 23.5%;
}
.error-message {
  color: red;
  font-size: 14px; /* Adjust size as needed */
}

.flex_input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  width: 100%;
}
@media (max-width: 991px) {
  .flex_input {
    display: flex;
    flex-direction: column;
  }.custom-width-50{
    width: 100% ;
  }
  .document-item{
width: 100% !important;
  }
  .row_col2{
    width: 100%;
  }
}
.info_btm {
  padding-top: 12px;
  padding-bottom: 10px;
}
.info_btm p {
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #2c357d;
}
.flex_btn_next {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-top: 40px;
}

.btn_left {
  width: 10%;
}

.btn_left button {
  gap: 10px;
  border-radius: 6px;
  opacity: 1px;
  background: #2c357d;
  color: white;
  padding: 10px 60px;
  border: none;
}
.btn_left button:hover {
  cursor: pointer;
}
.btn_job {
  padding-top: 20px;
}

.btn_job button {
  gap: 10px;
  border-radius: 6px;
  opacity: 1px;
  background: #2c357d;
  color: white;
  padding: 10px 60px;
  border: none;
}
.btn_job button:hover {
  cursor: pointer;
}
.btn_save {
  width: 10%;
  border-radius: 6px;
}
.btn_save button {
  /* padding: 10px 12px 10px 12px; */
  padding: 10px 60px;
  border-radius: 6px;
  color: #fff;
  background-color: #2c357d;
  border: none;
}
.btn_save button:hover {
  cursor: pointer;
}
.btn_pre {
  padding: 10px 49px;
  border: 1px solid #2c357d;

  border-radius: 6px;
}
.btn_pre button {
  /* padding: 10px 12px 10px 12px; */
  border-radius: 6px;
  color: #2c357d;

  border: none;
  background-color: transparent;
}
.btn_pre button:hover {
  cursor: pointer;
}
.tab_button {
  background-color: transparent;
  border: none;
  padding: 15px 0;
  cursor: pointer;
  color: #828384; /* Default text color */
  border-bottom: 2px solid transparent; /* Initial transparent border */
}

.tab_button.active {
  color: #2c357d; /* Active text color */
  border-bottom: 2px solid #2c357d; /* Blue border when active */
}
.tab_button.activeness {
  color: red; /* Active text color */
}
input[type="date"] {
  /* appearance: none;
  -webkit-appearance: none; 
  padding: 8px; */
}
/* .info_btm {
  padding: 20px;
} */

.section-title {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.flex_input {
  margin-bottom: 4px;
}
.document-item {
  margin-bottom: 20px;
  width: 23%;
}



.doc-title {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #2c357d80;
  padding: 0 10px;
}

.document-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #2c3e50;
  padding: 10px;
  border-radius: 5px;
}

.icon-text-wrapper {
  display: flex;
  align-items: center;
}

.doc-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.doc-name {
  font-size: 18px;
  color: #2c3e50;
}

.upload-btn {
  background-color: #2c3e50;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.upload-btn:hover {
  background-color: #1a2432;
}
.upload_input {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.doc-title {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #2c357d80;
  padding: 0 10px;
}

.document-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #2c3e50;
  padding: 10px;
  border-radius: 5px;
}

.icon-text-wrapper {
  display: flex;
  align-items: center;
}

.doc-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.doc-name {
  font-size: 18px;
  color: #2c3e50;
}

.upload-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Arial, sans-serif;
  color: #4e4e95;
  padding-top: 10px;
  padding-bottom: 10px;
}

.upload-title {
  font-size: 18px;
  margin-bottom: 8px;
}

.upload-container {
  width: 120px;
  height: 120px;
  background-color: #d7d9ea;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}

.upload-container input[type="file"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.upload-container .icon {
  font-size: 14px;
  color: #ffffff;
}

.upload-text {
  font-size: 14px;
  margin-top: 10px;
}
