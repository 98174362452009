.store_card .store_text h5 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #2c357d;
  padding-left: 12px;
}
.selector_for_table {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 45px;
}
.selector_for_table .checkbox .checkbox_text {
  display: flex;
  justify-content: space-between;
}
.selector_for_table .checkbox {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
}

.selector_for_table h5 {
  margin-bottom: 8px;
}

.checkbox button {
  background-color: #2c357d;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: white;
  /* padding: 2 15px !important; */
  padding: 8px 24px;
}
.checkbox_text p {
  font-size: 9px;
  font-weight: 500;
  /* line-height: 20px; */
  text-align: left;
  color: "#2C357D";
}
.table_attendance table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
.table_attendance tr {
  border-bottom: none;
}
.table_attendance th,
.table_attendance td {
  padding: 4px;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
}

.table_attendance th {
  border-top: none;
  border-bottom: none;
  background-color: #e5f1f8;
  font-weight: bold;
  border-left: 1px solid #252c58;
  border-right: 1px solid #252c58;
}

.table_attendance th:first-child,
.table_attendance td:first-child {
  border-left: none;
}

.table_attendance th:last-child,
.table_attendance td:last-child {
  border-right: none;
}

.table_attendance td {
  border-top: none;
  border-bottom: 1px solid #252c58;
  border-left: 1px solid #252c58;
  border-right: 1px solid #252c58;
}
.exportbtnss {
  background-color: #fff;
  width: 55px;
  height: 75px;
  right: 0px;
}
.exportbtnss li {
  list-style: none;
  color: #252c58;
  text-align: center;
  cursor: pointer;
}
.exportbtnss li:hover {
  background-color: #9a9fbe;
}
