/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Arial", sans-serif;

}

h2 {
  color: #2c357d;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

input[type="file"] {
  padding: 5px;
}

.file-note {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

.submit-btn {
  grid-column: span 2;
  background-color: #2c357d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.submit-btn:hover {
  background-color: #1f285e;
}

@media (max-width: 768px) {
  .form {
    grid-template-columns: 1fr;
  }
} */
.content_common {
  border-bottom: 2px solid #2c357d2e solid #2c357d2e;
}
.addstore {
  /* padding: 10px 10px; */
  padding: 25px 30px;
  padding-bottom: 10px;
}
.addstore h2 {
  color: #2c357d;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  /* border-bottom: 1px solid #2c357d2e; */
  /* padding-bottom: 10px; */
}
.addstore .label_name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 18px; */
  padding-top: 12px;
  /* padding: 18px 2px; */
  padding: 18px 18px;
  /* width: 44%; */
  width: 40%;
  border-right: 2px solid #2c357d2e;
}
.addstore .label_name label {
  color: #2c357d;
  /* font-size: 16px; */
  font-size: 14px;
  font-weight: 400;
  /* line-height: 24px; */
  text-align: left;
  /* height: 60px; */
}
.addstore .input_group {
  /* width: 52%; */
  width: 56%;
  display: flex;

  flex-direction: column;
  gap: 30px;
  padding: 1px 0px;
}
.addstore .form-group {
  padding-top: 10px;
}
.addstore .input_group input {
  padding: 5px 10px;
  border: 1px solid #2c357d2e;
  color: #2c357d;
  width: 100%;
  border-radius: 6px;
  /* margin-top: 8px; */
  text-align: left;
}
.addstore .input_group select {
  padding: 5px 0px;
  border: 1px solid #2c357d2e;
  color: #2c357d;
  width: 100%;
  border-radius: 6px;
  /* margin-top: 8px; */
  text-align: left;
}
.addstore .custom-select-container {
  padding: 5px 0px;
  border: 1px solid #2c357d2e;
  width: 100%;
  border-radius: 6px;
  color: #2c357d;
}

/* .file-input-container {
  display: flex;
  align-items: center;
}

.file-input::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 25px; 
  background-color: #333; 
  margin-left: 10px; 
} */

input[type="file"]::file-selector-button {
  background-color: transparent;
  color: #2c357d;

  cursor: pointer;
  /* border-right: 2px solid black !important; */
}

/* Hover effect for the file input button */
input[type="file"]::file-selector-button:hover {
  background-color: transparent; /* Darker green on hover */
}

/* Optional: styling the text of the file input */
input[type="file"] {
  color: #2c357d; /* Change the color of the text showing the file path */
}
.btnblue {
  padding-top: 10px;
}

/* Time Fields Styling */
.time-fields {
  margin-top: 20px;
}

.time-field-group {
  margin-bottom: 15px;
}

.time-input {
  width: 60px;

  text-align: center;
}

.time-dropdown {
  width: 60px;
  text-align: center;
  padding-bottom: 10px;
}

/* Basic Styling for Labels and Inputs */
/* label {
  font-weight: bold;
} */

.form-control .time-input {
  border-radius: 4px;
  padding: 8px;
}

.btnblue button {
  background-color: #2c357d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.btnblue button:hover {
  background-color: #fff;
  color: #2c357d;
  border: 1px solid #2c357d;
}
