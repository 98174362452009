.selector_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.disabled_option {
  color: #999;
  pointer-events: unset;
  cursor: not-allowed;
  background-color: rgb(218, 214, 214);
  padding: 10px;

  display: flex;
  align-items: center;

  transition: background-color 0.2s ease;
}

.selector_label {
  /* margin-bottom: 5px; */
  font-weight: 400;
  padding-left: 10px;
  color: #2c357d80;
  font-size: inherit;
}

.dropdown_icon {
  transition: transform 0.3s;
  margin-left: 8px;
}

.dropdown_icon.open {
  transform: rotate(180deg);
}

.custom_selector {
  padding: 3px 10px;
  font-size: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  /* border-bottom: 1px solid #2c357d; */
  border-bottom: 1px solid #2c357d;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #2c357d;
  display: flex;

  align-items: center;
}

.custom_selector:hover {
  /* background-color: #f9f9f9; */
}

.custom_dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  max-height: 150px;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.custom_option {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #2c357d;
  transition: background-color 0.2s ease;
}

.custom_option input[type="checkbox"] {
  margin-right: 10px;
  accent-color: #2c357d; /* Custom color for checkboxes */
}

.custom_option:hover {
  background-color: #2c357d26;
  color: #2c357d;
}

.custom_option:active {
  background-color: #2c357d;
  color: white;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}
