.selector_wrapper {
  position: relative;
  display: inline-block;
}

.selector_wrapper.custom_selector {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid #2c357d !important;
  border-radius: 0;
}

.dropdown_icon {
  transition: transform 0.3s ease;
}

.dropdown_icon.open {
  transform: rotate(180deg);
}

.custom_dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: none;
  border-radius: 4px;
  margin-top: 5px;
  overflow-y: scroll;
  z-index: 10;
}

.custom_option {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  color: #2c357d;
  cursor: pointer;
}

.custom_option:hover {
  background-color: #e3f2fd; /* Light blue for hover effect */
}

.custom_option + .custom_option {
  border-top: 1px solid #2c357d;
}

.section_header {
  padding: 8px 10px;
  font-weight: bold;
  color: #2c357d;
  /* background-color: #f9f9f9; */
}

/* .divider {
  display: block;
  text-align: center;
  margin: 1rem 0;
  overflow: hidden;
  white-space: nowrap;
} */
