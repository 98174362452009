/* General link styles */
.link {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #ffffff;
  text-decoration: none;
  background-color: transparent; /* Default background */
  transition: background-color 0.2s ease;
}

/* Hover effect for non-active links */
.link:hover {
  background: #e5f1f8;
  color: #2c357d;
}

/* Active link background color and border */
.link.active {
  background: #e5f1f8; /* Background color when active */
  /* border-left: 4px solid #2c357d;  */
  color: #2c357d;
}

/* Submenu link styles */
.submenu_link {
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 15px;
  font-weight: 400;
  color: #2c357d;
  text-decoration: none;
}

.submenu_link.active {
  border-left: 4px solid #1c247d;
  color: #1c247d;
}

/* Adjust submenu hover */
.submenu_link:hover {
  /* background: #e5f1f8; */
  color: #2c357d;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.routes a {
  cursor: pointer;
}
.link {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  color: white;
  gap: 10px;
  padding: 10px 10px;

  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  cursor: pointer;

  color: #2c357d;
  text-decoration: none;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.menu_item.active {
  background-color: #e5f1f8; /* Replace with the color you want */
}

.menu {
  display: flex;
  color: white;

  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu:active {
  background: #e5f1f8;
}

.menu_item {
  display: flex;
  align-items: center;
  padding: 12px 10px;
  gap: 3px;
  justify-content: center;
}
.hello {
  /* border-bottom: 1px solid #2c357d; */
  background: #e5f1f8;
}
.hello2 {
  border-bottom: 1px solid #2c357d;
}
.nohello {
  border-bottom: 1px solid #2c357d;
}
.hello:hover {
  background: #e5f1f8;
}
.hello:active {
  background: #e5f1f8;
}

.modal-backdrop.show {
  opacity: 0.1 !important;
}
.menu_container {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.submenu_item {
  display: flex;
  align-items: center;
  padding: 4px 8px;
}
.menu {
  display: flex;
  flex-direction: column;
}

.link_text {
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  color: #2c357d;
  margin-left: 5px;
}
/* .link_text:hover {
  background: #e5f1f8;
} */
/* Main link */
/* Main link */
.link {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #ffffff;
  text-decoration: none;
}

.link:hover {
  background: #e5f1f8;
}
.link:active {
  background: #e5f1f8;
}
.submenu_link {
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 15px;
  font-weight: 400;
  color: #2c357d;
  text-decoration: none;
}

.submenu_link.active {
  border-left: 4px solid #1c247d;
  color: #1c247d;
}

.submenu_container {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  /* border-bottom: 1px solid #1c247d; */
}

/* Submenu arrow */
.submenu_arrow {
  margin-left: auto;
  transition: transform 0.1s ease;
}
.active_background {
  background-color: #f0f0f5; /* Customize to desired background color */
}
/* @media (max-width: 999px) {
  .active-navbar {
    left: 0px;
    box-shadow: 0px 4px 63px 0px rgba(69, 162, 158, 0.1490196078);
  }
}
.responsivenavbar-wrapper {
  position: absolute;
  z-index: 99999;
  background-color: #ffffff;
}
@media (max-width: 481px) {
  .active-navbar {
    width: 100%;
  }
} */

/* .responsivenavbar-wrapper {
  position: fixed;
  top: 0;
  left: -100%;
  width: 250px;
  height: 100%;
  background-color: #2c357d;
  transition: left 0.3s ease-in-out;
  z-index: 1000;
}

.active-navbar {
  left: 0;
}

.close-button {
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
} */

/* Responsive Navbar Wrapper */
.responsivenavbar-wrapper {
  position: fixed;
  top: 0;
  left: -250px; /* Initially hidden to the left */
  width: 250px;
  height: 100%;
  background-color: #2c357d; /* Sidebar background color */
  transition: left 0.3s ease-in-out;
  z-index: 1000; /* Ensure it's above other elements */
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
}

.active-navbar {
  left: 0; /* Slide in the sidebar */
}

/* Close Button */
.close-button {
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
}

/* Sidebar Links */
