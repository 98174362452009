.view-requests {
  margin-top: 15px;
  background-color: #ffffff;
  padding: 20px 22px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.view-requests .table {
  margin-bottom: 0;
}

.view-requests tr {
  border-bottom: 1.19px solid #d5d9dd;
}
.view-requests th {
  border-top: 1.19px solid #d5d9dd;
}
.view-requests th,
.view-requests td {
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  padding: 12px;
  border: none;
}

.view-requests thead th {
  font-weight: 600;
}

.view-requests .badge {
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 300;
}

.view-requests .badge-approved {
  color: #2c357d;
  background: #e6effc;
}

.view-requests .badge-pending {
  background: #f2994a40;
  color: #ffb62e;
}

.view-requests .badge-rejected {
  background: #ff323240;
  color: #ff3232;
}
.view-requests .badge-arrival {
  background: #fff8e7;
  color: #d5b500;
}
.view-requests .badge-Half-Day {
  background: #efefef;
  color: #8a8a8a;
}
.view-requestb .badge-arrival {
  background: #f2994a40;
  color: #ffb62e;
}
.view-requestb .badge-Present {
  background: #ff323240;
  color: #ff3232;
}
.view-requestb .badge-Present {
  background: #a2eddb40;
  color: #24e502;
}
.view-requestb .badge-Absent {
  background: #ff323240;
  color: #ff3232;
}

.view-requestb .btn-view {
  padding: 15px 25px;
  font-size: 14px;
  border: 0.65px solid #2c357d;
  color: #2c357d;
  width: 81px;
}
.view-requestb .btn-view:hover {
  padding: 15px 25px;
  font-size: 14px;
  color: #fff;
  width: 81px;
  background-color: #2c357d;
}
