.delete_confirm_popup {
  width: 20%;
  height: 400;
  border-radius: 10px;
  opacity: 0px;
  color: #fff;
  overflow-y: hidden;
  padding: 48px 0px;
  border-radius: 10px;
}
.react-responsive-modal-closeButton {
  display: none;
}
.react-responsive-modal-modal.delete_confirm_popup {
  max-width: 800px !important;
  display: inline-block !important;
  text-align: left !important;
  vertical-align: middle !important;
  background: #ffffff !important;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25) !important;
  margin: 1.2rem !important;
  padding: 1.2rem !important;
  position: relative !important;
  overflow-y: auto !important;
  border-radius: 5px !important;
}

.header_bar {
  padding-top: 0px;
  text-align: center;
}
.body_sec {
  padding: 15px 0;
}
h4 {
  font-family: "Inter";
  font-size: 25px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  color: #2c357d;
}

p {
  font-size: 20px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: center;
  color: #2c357d;
}

.btn_sec {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.btn_sec.del_btn {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.del_btn button {
  background-color: #ff6f61;
  width: 108px;
  height: 36px;
  border: none;
  color: #fff;
  font-weight: 600;
}
.btn_sec .cancel_btn {
  background-color: #fff;
}
.cancel_btn button {
  width: 108px;
  height: 36px;
  border: 1px solid #2c357d;
  color: #2c357d;
  font-weight: 600;
  background-color: #fff;
}
