.table_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.table_wrapper .top_section {
  padding: 1px 0px;
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
  margin-bottom: 14px;
}



.table_wrapper .top_section .row_setion {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table_wrapper .top_section .row_setion .btn_flex {
  display: flex;
}

.table_wrapper .top_section .row_setion .btn_flex .btn_add {
  background: #000000;
  color: #fff;
  cursor: pointer;
}

.table_wrapper .table_content {
  background-color: #fff;
  width: 98%;
  border-radius: 10px;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
  height: auto;
  padding-top: 15px;
}

.table_wrapper .table_content .btn_add {
  background: #fbb742;
  color: #fff;
  cursor: pointer;
}
/* .btn_findEmployee {
  margin-top: 12px;
  width: 20%;
} */
.btnfindname {
  margin-top: 12px;
  width: 20%;
  display: flex;
  justify-content: space-around;
}
.btn_findEmployee .btnsss {
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #fff;
  border-radius: 6px;
  border: none;
  background-color: #2c357d;
}
@media (max-width: 1867px) {
  .btn_findEmployee .btnsss {
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #fff;
    border-radius: 6px;
    border: none;
    background-color: #2c357d;
  }
}
@media (max-width: 1699px) {
  .btn_findEmployee .btnsss {
    padding: 15px;

    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: #fff;
    border-radius: 6px;
    border: none;
    background-color: #2c357d;
  }
}
@media (max-width: 1299px) {
  .btn_findEmployee .btnsss {
    padding: 10px;

    font-size: 10px;
    font-weight: 500;
    text-align: left;
    color: #fff;
    /* border-radius: 6px; */
    border: none;
    background-color: #2c357d;
  }
}
.btn_findEmployee .btnsss2 {
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #fff;
  border-radius: 6px;
  border: none;
  background-color: #2c357d;
}
@media (max-width: 1867px) {
  .btn_findEmployee .btnsss2 {
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #fff;
    border-radius: 6px;
    border: none;
    background-color: #2c357d;
  }
}
@media (max-width: 1699px) {
  .btn_findEmployee .btnsss2 {
    padding: 15px;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: #fff;
    border-radius: 6px;
    border: none;
    background-color: #2c357d;
  }
}
@media (max-width: 1299px) {
  .btn_findEmployee .btnsss2 {
    padding: 9px;
    font-size: 10px;
    font-weight: 500;
    text-align: left;
    color: #fff;
    /* border-radius: 6px; */
    border: none;
    background-color: #2c357d;
  }
}
@media (max-width: 991px) {
  .right-item{
    flex-direction: column;
  }
  .classofnew{
    flex-direction: column;
  }
  .btnfindname{
    width: 100%;
  }
  .btn_findEmployee{
    width: 40%;
  }
  .btnsss{
    width: 100%;
  }
  .btnsss2{
    width: 100%;
  }
}
.filters {
  margin-top: 11px;
  background: #cacddf;
  width: 200px;
  padding: 10px 6px;
  top: 443px;
  left: 1685.96px;
  gap: 0px;
  border-radius: 6px;
  opacity: 1px;
  height: 48px;
}

.filters .calendars h4 {
  color: #fff;
  font-size: 16.82px;
  font-weight: 300;
  line-height: 25.23px;
  text-align: left;
}
.calendars {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.outer_border {
  border-radius: 0px;
  border-width: 0.6px;

  border-style: solid;

  border-color: #2c357d;
  box-shadow: 4px #66666640;
  border-top: none;
  width: 100%;
  padding: 10px 10px;
  position: relative;
}
.outer_border input {
  width: 100%;

  padding: 10px 10px;

  border-radius: 10px;

  border: 0.5px solid #2c357d;
}
.outer_border input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  color: #2c357d;
}
.outer_border img {
  position: absolute;
  right: 24px;
  bottom: 25px;
}
/* @media (max-width: 1870px) {
  .outer_border img {
    position: absolute;
    right: 20px;
    bottom: 44px;
  }
}
@media (max-width: 1699px) {
  .outer_border img {
    position: absolute;
    right: 20px;
    bottom: 43px;
  }
} */
.outer_border select {
  background: transparent;
  border: none;
  border: 0.5px solid #2c357d;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none; /* Firefox */
  appearance: none; /* Standard */
  background: none; /* Remove background image/icon */
}
