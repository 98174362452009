/* Main container for the entire progress component */
.progress-container {
  position: relative;
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Outer border with white background and inner/outer shadows */
.progress-bor {
  width: 140px;
  height: 140px;
  background: rgb(217 217 217 / 34%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 2.4px 3px #66666640, 0px 0px 26.7px 4px #66666640;
  position: relative;
}

/* Inner progress bar */
.progress-circular {
  width: 100px; /* Adjust size as needed */
  height: 100px;
  background: #ffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Centered text inside the progress bar */
.progress-text {
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  color: black;
  z-index: 2; /* Ensure it's above the progress bar */
  font-family: "Merriweather", serif; /* Optional: Use custom font */
}

/* Percentage display below the progress bar */
.percentage-display {
  font-size: 14px;
  color: #2c357d; /* Navy blue text color */
  margin-top: 8px;
  font-weight: bold;
  background-color: #f0f0f0;
  padding: 2px 6px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
